/* @tailwind base;

@tailwind components;

@tailwind utilities; */

/* html {
    @apply h-full;
}

body {
    @apply h-full;
}

div#__next {
    @apply h-full;
}

main {
    @apply h-full;
} */

html,
body {
    margin: 0;
    /* height: 100%; */
    overflow: hidden
}

.loader {
    box-sizing: border-box !important;
    position: absolute !important;
    border: 5px solid #b62c2c;
    /* border-radius: 50%; */
    /* border-top: 5px solid #db4a34; */
    width: 100% !important;
    height: 100% !important;
    /* -webkit-animation: spin 2s linear infinite;
    /* Safari */
    /*animation: spin 2s linear infinite;
    */
}

/* @media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }

    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
} */

Safari @-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: "AssistantRegular";
    src: local("AssistantRegular"),
        url("./fonts/slkscrb.ttf") format("truetype");
    font-weight: normal;
}

@keyframes shake {

    10%,
    90% {
        transform: translateX(-5px);
    }

    20%,
    80% {
        transform: translateX(5px);
    }

    30%,
    50%,
    70% {
        transform: translateX(-5px);
    }

    40%,
    60% {
        transform: translateX(5px);
    }
}

.dissolve {
    opacity: 0;
    transition: opacity 1s;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* img,
div {
    width: 100px;
    height: 100px;
} */

/* .x {
    animation: x 13s linear infinite alternate;
}

.y {
    animation: y 7s linear infinite alternate;
}

.z {
    animation: z 4s linear infinite;
} */


@keyframes x {
    100% {
        transform: translateX(calc(100vw - 100px));
    }
}

@keyframes y {
    100% {
        transform: translateY(calc(120vh - 100px));
    }
}

blink {
    -webkit-animation-name: blink;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(1.0, 0, 0, 1.0);
    -webkit-animation-duration: 1s;
}

.keyboard-button {
    width: min(8vw, 60px);
    font-size: min(3vh, 30px);
    font-weight: 700;
    padding: min(1vmin, 6px);
    color: white;
    background-color: rgba(185, 10, 133, 0.853);
    border-radius: 0.5rem;
    font-family: 'CustomFont2';
    margin: 0.3vmin 0.5vmin;
    cursor: pointer;
    text-transform: uppercase;
}

.keyboard-button-special {
    font-size: min(3vh, 30px);
    font-weight: 700;
    padding: min(1vmin, 6px);
    color: white;
    background-color: rgba(185, 10, 133, 0.853);
    border-radius: 0.5rem;
    font-family: 'CustomFont2';
    margin: 0.3vmin 0.5vmin;
    cursor: pointer;
    text-transform: uppercase;
}

.keyboard-button-special2 {
    font-size: min(3vh, 30px);
    font-weight: 700;
    padding: min(0.4vmin, 6px);
    color: white;
    background-color: rgba(185, 10, 133, 0.853);
    border-radius: 0.5rem;
    font-family: 'CustomFont2';
    margin: 0.3vmin 0.5vmin;
    cursor: pointer;
    text-transform: uppercase;
}

/* Add this CSS for the modal overlay */
.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Add this CSS for the modal content */
.modal-content {
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: "CustomFont2";
    font-weight: italic;
    font-size: 0.5rem;
}

/* Add some additional styles to position the "Do not show again" toggle */
.modal-content>div {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.modal-content label {
    margin-left: 5px;
}

.modal-content button {
    margin-top: 15px;
}