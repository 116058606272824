@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/slkscrb.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont2';
    src: url('./fonts/slkscr.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'CustomFont', sans-serif;
    font-family: 'CustomFont2', sans-serif;

}